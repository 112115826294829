import Logo from "../../public/img/logoj4j.png";
import "../../api.css"
import Seo from "../../utils/Seo";
import { SUPPORT_SERVER } from "../../App";

const Api = () => {
    const scroll_to_link = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
        var elems = document.querySelectorAll(".content-menu ul li");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
        return false;
    }

    const menuToggle = () => {
        document.querySelector('html').classList.toggle('menu-opened');
    }

    return (
        <>
            <Seo dynamic={true}
                title={"Join4Join Api Documentation: Make your own Join4Join bot"}
                description={"Use our easy api to create your own join4join bot and to earn loads of rewards!. Each developer receives free rewards with the developer affiliation system."}
            />
            <html className="no-js" lang="en">
                <meta charset="utf-8" />
                <title>Join4Join Api - Documentation</title>
                <link rel="stylesheet" href="css/style.css" media="all" />
                <body className="one-content-column-version">
                    <div className="left-menu">
                        <div className="content-logo">
                            <div className="logo">
                                <img src={Logo} alt="Join4Join Logo" height="32" />
                                <span>Api - Documentation</span>
                            </div>
                            <button className="burger-menu-icon" id="button-menu-mobile" onClick={() => { menuToggle() }}>
                                <svg width="34" height="34" viewBox="0 0 100 100">
                                    <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                                    <path className="line line2" d="M 20,50 H 80"></path>
                                    <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="mobile-menu-closer"></div>
                        <div className="content-menu">
                            <div className="content-infos">
                                <div className="info"><b>Version:</b> 1.0.0</div>
                                <div className="info"><b>Last Updated:</b> 06/10/2022</div>
                                <div className="info"><b>Documentation created with:</b> <a target={"_blank"} href="https://github.com/ticlekiwi/API-Documentation-HTML-Template">source code</a></div>
                            </div>
                            <ul>
                                <li className="scroll-to-link active" onClick={() => { scroll_to_link("content-get-started") }}>
                                    <a>Get Started</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-create-user") }}>
                                    <a>Create User</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-user") }}>
                                    <a>Get User</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-buy") }}>
                                    <a>Buy</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-farm") }}>
                                    <a>Farm</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-pay") }}>
                                    <a>Pay</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-info") }}>
                                    <a>Info</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-check") }}>
                                    <a>Check</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-daily") }}>
                                    <a>Daily</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { scroll_to_link("content-get-check_all") }}>
                                    <a>Check All</a>
                                </li>
                                <li className="scroll-to-link" onClick={() => { window.location.replace(SUPPORT_SERVER) }}>
                                    <a>Support</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="content-page">
                        <div className="content">
                            <div className="overflow-hidden content-section" id="content-get-started">
                                <h1>Get started with the Join4Join API</h1>
                                <p>
                                    The Join4Join API offers developers the ability to create their own Join4Join bot.
                                </p>
                                <p>
                                    To utilize this API, you will need to obtain an API key. Please create your API key on your developer page, which can be found <a href="/dashboard/settings?developer" target="_blank"><strong>here</strong></a>.
                                </p>
                                <p>
                                    You can find an example on GitHub that demonstrates how to use the Join4Join API. The example can be accessed <a href="https://github.com/join4join/nodejs-join4join-example" target={"_blank"}><strong>here</strong></a>.<br />
                                    Additionally, you have the option to host your own Join4Join bot for free. You can do so by visiting <a href="https://bot-hosting.net" target={"_blank"}><strong>this page</strong></a>.
                                </p>
                                <h2>Developers Rewards</h2>
                                <p>To help developers to grow their bots we added some rewards for them.</p>
                                <p>When developers utilize the API, they receive 3 coins and 0.10 coins for each server farm that their bot attracts from users created through the API.</p>
                                <h2>Rate Limit</h2>
                                <p>Please note that there is a limit of 50 requests per minute that cannot be exceeded.</p>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-create-user">
                                <h2>create user</h2>
                                <p>
                                    To create a user, please initiate a POST request to the following URL:<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/user/create</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The user id. (he'll be automatically affiliate with your account)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>The user informations.</td>
                                        </tr>
                                        <tr>
                                            <td>data.id</td>
                                            <td>string</td>
                                            <td>The user id.</td>
                                        </tr>
                                        <tr>
                                            <td>data.coins</td>
                                            <td>float</td>
                                            <td>The user coins.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-user">
                                <h2>get user</h2>
                                <p>
                                    To get an user. you need to make a GET call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/user/get</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The user id.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>The user informations.</td>
                                        </tr>
                                        <tr>
                                            <td>data.id</td>
                                            <td>string</td>
                                            <td>The user id.</td>
                                        </tr>
                                        <tr>
                                            <td>data.coins</td>
                                            <td>float</td>
                                            <td>The user coins.</td>
                                        </tr>
                                        <tr>
                                            <td>data.history</td>
                                            <td>array</td>
                                            <td>The last 20 history of the user.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-buy">
                                <h2>buy</h2>
                                <p>
                                    Make a join4join Ad. You'll be able to buy an ad automatically if you use your own id else for others user
                                    return a link. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/buy</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The user id.</td>
                                        </tr>
                                        <tr>
                                            <td>coins</td>
                                            <td>int</td>
                                            <td>Amount of coins he wants to spend in the ad.</td>
                                        </tr>
                                        <tr>
                                            <td>invite</td>
                                            <td>string</td>
                                            <td>The invite of the server.</td>
                                        </tr>
                                        <tr>
                                            <td>filter_language</td>
                                            <td>string</td>
                                            <td>The language of the user.</td>
                                        </tr>
                                        <tr>
                                            <td>filter_account</td>
                                            <td>boolean</td>
                                            <td>Allow the alt filter.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>link</td>
                                            <td>string</td>
                                            <td>The link for the user to confirm the ad. (ONLY IF THE ID OF THE API OWNER IS DIFFERENT OF THE USER ID)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-farm">
                                <h2>farm</h2>
                                <p>
                                    To get farm servers. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/farm</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The id of the user.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>array</td>
                                            <td>The servers available to farm.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-pay">
                                <h2>pay</h2>
                                <p>
                                    Transfer coins. You'll be able to transfer the coins automatically if you use your own id else for others user
                                    return a link. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/pay</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_receiver</td>
                                            <td>string</td>
                                            <td>The user who will receive the coins.</td>
                                        </tr>
                                        <tr>
                                            <td>user_donator</td>
                                            <td>string</td>
                                            <td>The user who will donate the coins.</td>
                                        </tr>
                                        <tr>
                                            <td>coins</td>
                                            <td>int</td>
                                            <td>Amount of coins the donator wants to give.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>link</td>
                                            <td>string</td>
                                            <td>The link for the user to confirm the transfer. (ONLY IF THE ID OF THE API OWNER IS DIFFERENT OF THE USER ID)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-info">
                                <h2>info</h2>
                                <p>
                                    To get info about a server. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/info</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>guild_id</td>
                                            <td>string</td>
                                            <td>The ID of the guild.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>The guild informations.</td>
                                        </tr>
                                        <tr>
                                            <td>data.name</td>
                                            <td>string</td>
                                            <td>The guild name.</td>
                                        </tr>
                                        <tr>
                                            <td>data.icon</td>
                                            <td>string</td>
                                            <td>The guild icon.</td>
                                        </tr>
                                        <tr>
                                            <td>data.invite</td>
                                            <td>string</td>
                                            <td>The guild invite.</td>
                                        </tr>
                                        <tr>
                                            <td>data.ad</td>
                                            <td>string (yes or no)</td>
                                            <td>If the guild has an ad running.</td>
                                        </tr>
                                        <tr>
                                            <td>data.invitation_update</td>
                                            <td>int</td>
                                            <td>The number of members the bot need to invite.</td>
                                        </tr>
                                        <tr>
                                            <td>data.invitation_request</td>
                                            <td>int</td>
                                            <td>The number of members the user bought.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-check">
                                <h2>check</h2>
                                <p>
                                    To know if you can leave a server without losing coins. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/check</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>guild_id</td>
                                            <td>string</td>
                                            <td>The ID of the guild.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The ID of the user.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>The guild informations.</td>
                                        </tr>
                                        <tr>
                                            <td>data.check</td>
                                            <td>boolean</td>
                                            <td>Know if he can leave the server.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-daily">
                                <h2>daily</h2>
                                <p>
                                    To claim the 1 coins daily:<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/daily</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The ID of the user.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>Informations about the daily reward.</td>
                                        </tr>
                                        <tr>
                                            <td>data.amount</td>
                                            <td>int</td>
                                            <td>Number of coins for daily reward.</td>
                                        </tr>
                                        <tr>
                                            <td>data.ready</td>
                                            <td>boolean</td>
                                            <td>If you can claim or not the daily reward.</td>
                                        </tr>
                                        <tr>
                                            <td>data.remaining_time</td>
                                            <td>int</td>
                                            <td>Number of milliseconds left before to be able to claim your daily reward.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-check_all">
                                <h2>check all</h2>
                                <p>
                                    Get all servers a user can leave. you need to make a POST call to the following url :<br />
                                    <code className="higlighted break-word">https://join4join.xyz/api/v1/join4join/check/all</code>
                                </p>
                                <br />
                                <h4>QUERY PARAMETERS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>secret_key</td>
                                            <td>string</td>
                                            <td>Your API key.</td>
                                        </tr>
                                        <tr>
                                            <td>user_id</td>
                                            <td>string</td>
                                            <td>The ID of the user.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>QUERY RESULTS</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>success</td>
                                            <td>boolean</td>
                                            <td>See is the request was successfully execute.</td>
                                        </tr>
                                        <tr>
                                            <td>data</td>
                                            <td>json</td>
                                            <td>The guild informations.</td>
                                        </tr>
                                        <tr>
                                            <td>data.check</td>
                                            <td>array</td>
                                            <td>array of id.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        </>
    );
};

export default Api;